<template>
  <b-overlay :show="loading">
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <b-col cols="12" xl="9" md="8">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <!-- Header: Left Content -->
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <!-- <logo /> -->
                    <b-img :src="img" alt="" rounded width="70px" />
                    <h3 class="text-primary invoice-logo">ELP</h3>
                  </div>
                  <!--<p class="card-text mb-25">Office 149, 450 South Brand Brooklyn</p>
                      <p class="card-text mb-25">San Diego County, CA 91905, USA</p>
                      <p class="card-text mb-0">+1 (123) 456 7891, +44 (876) 543 2198</p>-->
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Invoice
                    <span class="invoice-number"
                      >: {{ billingDetail?.merchant_ref }}</span
                    >
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Status</p>
                    <p class="invoice-date">
                      :
                      <b-badge
                        v-if="billingDetail?.status == 'PAID'"
                        variant="success"
                        >Lunas</b-badge
                      >
                      <b-badge
                        v-else-if="billingDetail?.status == 'UNPAID'"
                        variant="warning"
                        >Belum Lunas</b-badge
                      >
                      <b-badge v-else variant="danger">{{
                        billingDetail?.status
                      }}</b-badge>
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Tanggal</p>
                    <p class="invoice-date">
                      : {{ humanDate(billingDetail?.created_at) }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Nama Produk</p>
                    <p class="invoice-date">
                      : {{ billingDetail?.order_items.name }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Tipe</p>
                    <p class="invoice-date">: {{ billingDetail?.type }}</p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <div>
                    <h6 class="mb-2">Konsumen :</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            {{ billingDetail?.customer_name }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ billingDetail?.customer_phone }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <p>{{ billingDetail?.customer_email }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0 mt-xl-0 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">Rincian Pembayaran :</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">Metode</td>
                          <td>: {{ billingDetail?.payment_name }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">Tipe</td>
                          <td>
                            :
                            {{
                              billingDetail?.payment_type == "payment_gateway"
                                ? "Payment Gateway"
                                : " Manual"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <b-table-lite responsive :fields="fields" :items="transaksiData">
              <!-- A virtual column -->
              <template #cell(index)="{ index, item }">
                <section v-if="item?.image">
                  <img
                    :src="item?.image"
                    alt="Produk Image"
                    style="width: 100px; height: 80px"
                  />
                </section>
                <span v-else>
                  {{ index + 1 }}
                </span>
              </template>
              <!-- A virtual composite column -->
              <template #cell(price)="data">
                {{ formatRupiah(data.value) }}
              </template>
              <template #cell(subtotal)="data">
                {{ formatRupiah(data.item.price * data.item.quantity) }}
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Note:</span>
                    <span class="ml-75">{{
                      billingDetail?.note != null ? billingDetail?.note : "-"
                    }}</span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Subtotal:</p>
                      <p class="invoice-total-amount">
                        Rp.{{ formatRupiah(pricePlusFee) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Discount:</p>
                      <p class="invoice-total-amount">
                        Rp.{{ formatRupiah(discountProduct) }}
                      </p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">
                        Rp. {{ formatRupiah(pricePlusFee - discountProduct) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              :to="{ name: 'billing' }"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-20" />
              Kembali
            </b-button>

            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              class="mb-75"
              block
              @click="printInvoice"
            >
              <feather-icon icon="PrinterIcon" class="mr-20" />
              Print
            </b-button>
            <b-button
              v-if="
                billingDetail?.payment_type == 'manual' &&
                billingDetail?.status == 'UNPAID' &&
                billingDetailOrder
              "
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-danger"
              class="mb-75"
              block
              @click="HapusBuktiTF"
            >
              <feather-icon icon="TrashIcon" class="mr-20" />
              Hapus Bukti Transfer
            </b-button>
            <b-button
              variant="outline-info"
              class="mb-75"
              block
              v-else-if="
                billingDetail?.payment_type == 'manual' &&
                billingDetail?.status == 'UNPAID' &&
                !billingDetailOrder
              "
              @click.prevent="redirectToPaymentPage"
            >
              Upload Bukti Transfer
            </b-button>
          </b-card>
          <!-- </b-col>
        <b-col cols="12" md="4" xl="3" class="invoice-actions"> -->
          <b-card v-if="billingDetail?.payment_type == 'manual'">
            <b-card-text
              ><b>Bukti Transfer</b>
              <br />
              <small v-if="billingDetailOrder != null"
                >(klik pada gambar untuk lihat gambar lebih jelas)</small
              >
              <small v-if="billingDetailOrder == null"
                >(belum tersedia bukti transfer)</small
              >
            </b-card-text>
            <div class="images" v-viewer>
              <b-img
                :src="
                  billingDetailOrder != null
                    ? apiFile + billingDetailOrder.file
                    : require('@/assets/images/elp/no-image.jpg')
                "
                class="card-img mb-2"
              >
              </b-img>
            </div>
            <hr />
            <b-card-text>
              <!-- <b-col cols="12" xl="6" class=""> -->
              <div>
                <h6 class="mb-2">Rekening :</h6>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">Kode</td>
                      <td>
                        :
                        {{
                          billingDetailOrder != null
                            ? billingDetailOrder?.rekening?.code
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Nama Bank</td>
                      <td>
                        :
                        {{
                          billingDetailOrder != null
                            ? billingDetailOrder?.rekening?.name
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Nomor Rek.</td>
                      <td>
                        :
                        {{
                          billingDetailOrder != null
                            ? billingDetailOrder?.rekening?.number
                            : "-"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- </b-col> -->
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BTableLite,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
import { customRef } from "@vue/composition-api";
Vue.use(VueViewer);

export default {
  components: {
    VueViewer,
    BImg,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BTableLite,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      img: require("@/assets/images/elp/logo.png"),
      id: null,
      loading: false,
      activeAction: null,
      label: "Simpan",
      //Data
      transaksiData: null,
      billingDetailOrder: {},
      form: {
        id: null,
        name: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: "Produk" },
        { key: "price", label: "Harga" },
        { key: "quantity", label: "Jumlah" },
        { key: "subtotal", label: "Total" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Transaksi" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Transaksi",
      search: null,
      idd: null,
      isDataLoaded: false,
      billingDetail: null,
    };
  },
  watch: {
    billingDetail(val) {
      if (val && val?.order_info) {
        this.getDataByOrder();
      }
    },
  },
  computed: {
    pricePlusFee() {
      const productPrice = parseFloat(this.billingDetail?.order_items?.price);
      const costumerFee = parseFloat(
        this.billingDetail?.fee_customer ? this.billingDetail?.fee_customer : 0
      );

      return productPrice + costumerFee;
    },
    discountProduct() {
      const product = this.thereIsProduct;
      if (!product) return false;

      let discount = this.billingDetail?.discount;
      if (!discount) return 0;

      const discountValue =
        discount.type == "percentage"
          ? (parseFloat(product?.price) * discount.value) / 100
          : parseFloat(product?.price) - discount.value;

      return discountValue;
    },
    finalPrice() {
      const plainPrice = parseFloat(this.billingDetail?.amount_received);
      const costumerFee = parseFloat(
        this.billingDetail?.fee_customer ? this.billingDetail?.fee_customer : 0
      );

      const discount = this.billingDetail?.discount
        ? parseFloat(this.billingDetail?.discount?.amount)
        : 0;

      return parseFloat(plainPrice + costumerFee - discount);
    },
    thereIsProduct() {
      if (!this.billingDetail) return false;

      const product = this.billingDetail?.order_items;
      if (!product) return false;

      return product;
    },
    transaksiId() {
      const { transaksi_id } = this.$route.params;

      return transaksi_id;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  setup() {
    const printInvoice = () => {
      window.print();
    };
    return {
      printInvoice,
    };
  },
  methods: {
    redirectToPaymentPage() {
      this.loading = true;

      let id = this.billingDetail?.order_id;
      this.$store
        .dispatch("transaksi/orderanSayaDetail", id)
        .then(async (res) => {
          this.loading = false;
          const order = res.data;
          const { transaksi_icon: icon_url, transaksi_info: transaction } =
            order;

          const paymentDetail = {
            type_payment: transaction?.payment_type,
            icon_url,
          };
          this.$store.commit("payment/SET_DETAIL", paymentDetail);

          transaction.total_harga = transaction.amount;
          transaction.order_id = id;
          transaction.instructions =
            transaction.instructions !== null
              ? JSON.parse(transaction.instructions)
              : null;

          const isManualTransfer = transaction?.payment_type === "manual";
          if (isManualTransfer) {
            transaction.message =
              "Pesanan anda akan segera diproses setelah kami menerima bukti transfer anda";
          }

          this.$store.commit("payment/SET_CURRENT_TRANSACTION", transaction);
          if (this.billingDetail?.type == "ebook") {
            // redirect to page bayar
            let dataState = {
              name: this.billingDetail?.order_items.name,
              price: this.billingDetail?.order_items.price,
              photo: this.billingDetail?.order_items.image_url,
              description: this.billingDetail?.order_items.description,
            };
            this.$store.commit("ebook/SET_DETAIL", dataState);
            this.$router.push({
              name: "payment-ebook-confirmation",
              params: {
                id: order?.order_info?.sku,
                obj: order,
              },
            });
          } else if (this.billingDetail?.type == "snbp") {
            this.$router.push({
              name: "payment-snbp-confirmation",
              params: {
                id: "snbp",
                obj: res.data,
              },
            });
          } else {
            let dataState = {
              name: this.billingDetail?.order_items.name,
              price: this.billingDetail?.order_items.price,
              thumbnail: this.billingDetail?.order_items.image_url,
              description: this.billingDetail?.order_items.description,
            };
            this.$store.commit("paketTryout/SET_DETAIL", dataState);
            this.$router.push({
              name: "payment-confirmation",
              params: {
                id: this.billingDetail?.order_items.sku,
                obj: res.data,
              },
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    show() {
      this.$viewerApi({
        images: this.apiFile + this.billingDetailOrder.file,
      });
    },
    HapusBuktiTF() {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: this.billingDetailOrder.id,
            fungsi: 2,
          };
          this.$store
            .dispatch("transaksiManual/deleteBuktiTF", payload)
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Transaksi";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Transaksi";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("jurusan/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.merchant_ref == null || this.form.merchant_ref == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.merchant_ref,
        school_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("transaksi/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.merchant_ref = null;
          this.Modal = false;
          // this.ModalEdit = false;
          this.getDataById();
          this.displaySuccess({
            text: "Transaksi berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataById() {
      this.$store
        .dispatch("transaksi/indexId", this.transaksiId)
        .then((response) => {
          let billingDetail = response.data;
          this.billingDetail = billingDetail;
          this.transaksiData = [billingDetail.order_items];

          this.getDataByOrder();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataByOrder() {
      const payload = {
        order_id: this.billingDetail.order_id,
      };
      this.$store
        .dispatch("transaksiManual/index", payload)
        .then(async (response) => {
          let billingDetailOrder = response.data.data;

          // file manual transfer
          this.billingDetailOrder = billingDetailOrder[0];

          const product = this.billingDetail?.order_items;
          if (this.thereIsProduct) {
            product.image = await this.getDetailProduct(
              this.thereIsProduct?.id
                ? this.thereIsProduct?.id
                : this.thereIsProduct?.sku,
              this.billingDetail?.type
            );
          }
          this.transaksiData = [product];
        })
        .catch((error) => {
          this.displayError(error);
          return false;
        });
    },
    async getDetailProduct(product_id, typeProduct) {
      let action =
        typeProduct.toLowerCase() == "ebook"
          ? "e-book/detail"
          : typeProduct.toLowerCase() == "snbp"
          ? "snbp/indexSetting"
          : "paketTryout/detail";
      if (["ebook", "course"].includes(typeProduct.toLowerCase())) {
        const params =
          typeProduct.toLowerCase() == "ebook"
            ? product_id
            : { id: product_id };
        const response = await this.$store.dispatch(action, params);
        const product = await response.data;
        if (product) {
          const imageProp =
            typeProduct.toLowerCase() == "ebook"
              ? product?.photo
              : product?.thumbnail;
          return `${process.env.VUE_APP_API_FILE}/${imageProp}`;
        }

        return null;
      }
    },
  },
  async created() {
    if (!this.transaksiId) {
      this.$router.go(-1);
    }
    this.getDataById();
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
